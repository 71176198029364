import { useEffect, useState } from "react";

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        // console.log('wtf', window)
        setWindowSize({
          width: window.innerWidth < 700 ? window.innerWidth : 700,
          height: window.innerHeight,
        });
      }
    
      // Add event listener
      window.addEventListener("resize", handleResize);
     
      // Call handler right away so state gets updated with initial window size
      handleResize();
    
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
// ------------------------------------------------------------

export const checkUsername = (username, setUsername, setError) => {
  setError(null);
  var filteredUsername = username.replace(/[^\w.]/gi, "").toLowerCase();
  if (
    filteredUsername[filteredUsername.length - 1] == "." ||
    filteredUsername[filteredUsername.length - 1] == "_"
  ) {
    if (
      filteredUsername.length > 1 &&
      filteredUsername[filteredUsername.length - 1] ==
        filteredUsername[filteredUsername.length - 2]
    ) {
      filteredUsername = filteredUsername.slice(0, -1);
    }
  }

  setUsername(filteredUsername);
};

export const unique = (arr) => {
  return [...new Map(arr.map((v) => [v?.id, v])).values()];
};

export const getTimeDifference = (dateTime, end) => {
  if (!dateTime) {
    return { value: -1, suffix: "" };
  }
  var date = dateTime.getTime();
  let diff = date - end.getTime();
  var msec = diff;

  let percentageDifference = (diff / date) * 100;
  if (msec < 0) {
    return { value: 0, suffix: "" };
  }
  var years = Math.floor(msec / 1000 / 60 / (60 * 24 * 365));
  msec -= years * 1000 * 60 * 60 * 24 * 365;
  var days = Math.floor(msec / 1000 / 60 / (60 * 24));
  msec -= days * 1000 * 60 * 60 * 24;
  var hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  var mm = Math.floor(msec / 1000 / 60);
  msec -= mm * 1000 * 60;
  var ss = Math.floor(msec / 1000);
  msec -= ss * 1000;

  if (years > 0) {
    return { value: years, suffix: "y", percentageDifference };
  } else if (days > 0) {
    return { value: days, suffix: "d", percentageDifference };
  } else if (hh > 0) {
    return { value: hh, suffix: "h", percentageDifference };
  } else if (mm > 0) {
    return { value: mm, suffix: "m", percentageDifference };
  }

  return { value: "", suffix: "a few seconds ago", percentageDifference: 100 };
};

export const getUsersName = (user) => {
  return user?.firstName + " " + user?.lastName;
};

export const getCityAndState = (item) => {
  let { city, state } = item?.address;
  return city + ", " + state;
};

export const getFullLocation = (item) => {
  let { city, state, zipCode, line1 } = item?.address;

  return `${line1 ? capitalize(line1) + ", " : ""}${capitalize(
    city
  )}, ${capitalize(state)} ${zipCode || ""}`;
};

function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

let shortFormatter = Intl.NumberFormat("en", {
  notation: "compact",
  maximumSignificantDigits: 3,
});

export function formatShortNumber(number) {
  return number ? shortFormatter.format(number) : 0;
}

export function formatURL(url) {
  let result = url;
  if (result.slice(0, 4) === "http") {
    return result;
  } else {
    return "http://" + result;
  }
}
