import { PrimaryText } from "components/Common/PrimaryText";
import { CustomInput } from "components/Inputs/CustomInput";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { CustomSwitch } from "components/Inputs/CustomSwitch";
import { COLORS } from "constants/Colors";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  ActivityIndicator,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native-web";
import { useWindowSize } from "utils/helpers";
import { useInstagram } from "context/instagramContext";
import { firestore, storage } from "../utils/firebase";

import max from "assets/max-headshot.png";
import { AddCircle } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import { Header } from "../components/Header";

export default function Contact() {
  const size = useWindowSize();
  const { instagramPhotos } = useInstagram();
  const [loading, setLoading] = useState(false);

  const [details, setDetails] = useState({});
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const [receiveNewsletter, setReceiveNewsletter] = useState(true);
  const [agreeToPolicy, setAgreeToPolicy] = useState(false);

  const [bodyPhotos, setBodyPhotos] = useState([]);
  const [referencePhotos, setReferencePhotos] = useState([]);

  useEffect(() => {
    if (instagramPhotos.length > 0) setLoading(false);
  }, [instagramPhotos]);

  //check if submit button is enabled
  useEffect(() => {
    // console.log("details", details);
    setSubmitEnabled(
      details.over18 &&
        details.name &&
        details.email &&
        details.phone &&
        details.location &&
        details.description &&
        details.month &&
        agreeToPolicy
    );
  }, [details, agreeToPolicy]);

  function photoSelected(id) {
    if (selectedPhotos.includes(id)) {
      setSelectedPhotos(selectedPhotos.filter((photo) => photo !== id));
    } else {
      setSelectedPhotos([...selectedPhotos, id]);
    }
  }

  function submitForm() {
    setLoading(true);

    updateInquiryPics();
  }

  const updateInquiryPics = () => {
    const inquiryRef = firestore
      .collection("users")
      .doc("maxbtattin")
      .collection("inquiries")
      .doc();
    let promises = [];
    const bodyPhotosURLS = [];
    const referencePhotoURLS = [];
    bodyPhotos.map((val, index) => {
      let ref = storage.ref(`${inquiryRef.id}/bodyPhotos/${index}.jpg`);

      promises.push(
        ref
          .put(val, { cacheControl: "max-age=31536000" })
          .then(async (result) => {
            // console.log("result", result);
            const downloadURL = await ref.getDownloadURL();
            bodyPhotosURLS.push(downloadURL);
          })
          .catch((err) => {
            console.log("err", err);
          })
      );
    });
    referencePhotos.map((val, index) => {
      let ref = storage.ref(`${inquiryRef.id}/referencePhotos/${index}.jpg`);

      promises.push(
        ref
          .put(val, { cacheControl: "max-age=31536000" })
          .then(async (result) => {
            // console.log("profile pic upload result", result);
            const downloadURL = await ref.getDownloadURL();
            referencePhotoURLS.push(downloadURL);
          })
          .catch((err) => {
            console.log("err", err);
          })
      );
    });

    Promise.all(promises).then(() => {
      inquiryRef
        .set({
          ...details,
          selectedPhotos,
          referencePhotos: referencePhotoURLS,
          bodyPhotos: bodyPhotosURLS,
          receiveNewsletter,
        })
        .then(() => {
          setLoading(false);
          // console.log("success");
          alert("Your inquiry has been submitted!");
          setDetails({});
          setSelectedPhotos([]);
          setBodyPhotos([]);
          setReferencePhotos([]);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
          alert(
            "There was an error submitting your inquiry. Please try again later."
          );
        });
    });
  };

  function handleReceiveNewsletter(e) {
    setReceiveNewsletter(e.target.checked);
  }

  function handleAgree(e) {
    setAgreeToPolicy(e.target.checked);
  }

  const style = styles(size);

  return (
    <>
      <View style={style.container}>
        <View style={style.body}>
          <ScrollView
            style={style.scrollView}
            contentContainerStyle={{ paddingBottom: 30 }}
            showsVerticalScrollIndicator={false}
          >
            <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
              <View style={{flex: 1}}/>
              <View>
              <Image
                source={max}
                style={{
                  borderRadius: 400,
                  width: size.width / 4,
                  height: size.width / 4,
                  marginBottom: 24,
                  alignSelf: "center",
                }}
                alt={"Max Bontrager"}
                resizeMode="cover"
              />
              <PrimaryText
                fontSize={20}
                fontWeight={800}
                style={{
                  marginBottom: 12,
                  color: COLORS.primary.white,
                  textAlign: "center",
                }}
              >
                CONTACT FORM
              </PrimaryText>
              </View>

              <Header />
            </View>
            <View style={style.inputContainer}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <PrimaryText
                  fontSize={14}
                  fontWeight={700}
                  style={style.inputTitle}
                >
                  {"Over 18?"}
                  <PrimaryText
                    fontSize={14}
                    fontWeight={700}
                    style={{ color: COLORS.primary.error }}
                  >
                    {"*"}
                  </PrimaryText>
                </PrimaryText>
                <CustomSwitch
                  value={details?.over18 || false}
                  title="Over 18?"
                  type="checkbox"
                  onChange={(value) =>
                    setDetails({ ...details, over18: value })
                  }
                  mandatory
                />
              </View>

              <CustomInput
                value={details?.name}
                title="Preferred Name"
                onChangeText={(text) => setDetails({ ...details, name: text })}
                mandatory
              />
              <CustomInput
                value={details?.pronouns}
                title="Pronouns"
                onChangeText={(text) =>
                  setDetails({ ...details, pronouns: text })
                }
                placeholder="she/her, he/him, they/them, etc."
              />
              <CustomInput
                value={details?.email}
                title="Email"
                onChangeText={(text) => setDetails({ ...details, email: text })}
                mandatory
                placeholder="email@email.com"
                style={{marginBottom: 0}}
              />
              <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
              <Checkbox defaultChecked checked={receiveNewsletter} onChange={handleReceiveNewsletter} style={{color: 'white'}} size={"small"} />
              <PrimaryText
                fontSize={12}
                fontWeight={300}
                // style={{ color: COLORS.primary.error }}
              >
                {"Receive updates from Max Bontrager"}
              </PrimaryText>
              </View>
              <CustomInput
                value={details?.phone}
                title="Phone #"
                onChangeText={(text) => setDetails({ ...details, phone: text })}
                mandatory
                placeholder="555-555-5555"
              />
              <CustomInput
                value={details?.location}
                title="Location on body"
                onChangeText={(text) =>
                  setDetails({ ...details, location: text })
                }
                mandatory
                placeholder="upper arm, lower back, etc."
              />
              <CustomInput
                value={details?.size}
                title="Estimated size"
                onChangeText={(text) => setDetails({ ...details, size: text })}
                placeholder="4x4, 6x6, etc."
              />
              <CustomInput
                value={details?.description}
                title="Description of tattoo"
                onChangeText={(text) =>
                  setDetails({ ...details, description: text })
                }
                mandatory
                style={{ marginBottom: 12 }}
                placeholder="flowers, animals, etc."
              />
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <PrimaryText
                  fontSize={14}
                  fontWeight={700}
                  style={style.inputTitle}
                >
                  Upload photo(s) of body part
                </PrimaryText>

                <input
                  type="file"
                  id="body-photo"
                  accept="image/*"
                  onChange={(event) => {
                    if (!event.target.files[0]) return;
                    // console.log(event.target.files[0]);
                    const photos = [...bodyPhotos, event.target.files[0]];
                    setBodyPhotos(photos);
                  }}
                  style={{
                    marginLeft: 12,
                    display: "none",
                  }}
                />
                <TouchableOpacity
                  style={{ marginLeft: 8 }}
                  onPress={() => document.getElementById("body-photo").click()}
                >
                  <AddCircle
                    style={{
                      width: 32,
                      height: 32,
                      color: COLORS.primary.lightBlue,
                    }}
                  />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {bodyPhotos?.length > 0 &&
                  bodyPhotos?.map((photo, index) => {
                    if (!photo) return null;
                    return (
                      <View style={{ marginHorizontal: 6, marginBottom: 12 }}>
                        <Image
                          alt="not fount"
                          style={{ height: 120, aspectRatio: 1 }}
                          source={URL.createObjectURL(photo)}
                          resizeMode="contain"
                        />
                        <br />
                        <button
                          onClick={() => {
                            const photos = [...bodyPhotos];
                            photos.splice(index, 1);
                            setBodyPhotos(photos);
                          }}
                        >
                          Remove
                        </button>
                      </View>
                    );
                  })}
              </View>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <PrimaryText
                  fontSize={14}
                  fontWeight={700}
                  style={style.inputTitle}
                >
                  Upload tattoo reference photo(s)
                </PrimaryText>

                <input
                  type="file"
                  id="reference-photo"
                  accept="image/*"
                  onChange={(event) => {
                    if (!event.target.files[0]) return;
                    // console.log(event.target.files[0]);
                    const photos = [...referencePhotos, event.target.files[0]];
                    setReferencePhotos(photos);
                  }}
                  style={{
                    marginLeft: 12,
                    display: "none",
                  }}
                />
                <TouchableOpacity
                  style={{ marginLeft: 8 }}
                  onPress={() =>
                    document.getElementById("reference-photo").click()
                  }
                >
                  <AddCircle
                    style={{
                      width: 32,
                      height: 32,
                      color: COLORS.primary.lightBlue,
                    }}
                  />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {referencePhotos?.length > 0 &&
                  referencePhotos?.map((photo, index) => {
                    if (!photo) return null;
                    return (
                      <View style={{ marginHorizontal: 6, marginBottom: 12 }}>
                        <Image
                          alt="not fount"
                          style={{ height: 120, aspectRatio: 1 }}
                          source={URL.createObjectURL(photo)}
                          resizeMode="contain"
                        />
                        <br />
                        <button
                          onClick={() => {
                            const photos = [...referencePhotos];
                            photos.splice(index, 1);
                            setReferencePhotos(photos);
                          }}
                        >
                          Remove
                        </button>
                      </View>
                    );
                  })}
              </View>
              <PrimaryText
                fontSize={14}
                fontWeight={700}
                style={style.inputTitle}
              >
                Please select a few of my works as inspiration
              </PrimaryText>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 12,
              }}
            >
              {instagramPhotos.map((photo) => {
                const { media_url, id } = photo;
                const selected = selectedPhotos.includes(media_url);
                return (
                  <TouchableOpacity
                    key={id}
                    onPress={() => photoSelected(media_url)}
                  >
                    <Image
                      source={{ uri: media_url }}
                      layout="fixed"
                      style={{
                        width: size.width / 5 - 2,
                        height: undefined,
                        margin: 1,
                        aspectRatio: 1,
                      }}
                      alt={id}
                      // resizeMode="contain"
                    />
                    <View
                      style={{
                        position: "absolute",
                        top: 2,
                        left: 2,
                        backgroundColor: "transparent",
                        borderColor: "white",
                        borderWidth: 2,
                        width: 20,
                        height: 20,
                        borderRadius: 10,
                        padding: 2,
                      }}
                    >
                      {selected && (
                        <View
                          style={{
                            backgroundColor: "white",
                            width: "100%",
                            height: "100%",
                            borderRadius: 10,
                            padding: 1,
                          }}
                        ></View>
                      )}
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
            <View style={style.inputContainer}>
              <CustomInput
                value={details?.month}
                title="Desired month, what days work best (leave blank if open to next available)"
                onChangeText={(text) => setDetails({ ...details, month: text })}
                mandatory
                placeholder="June, July, Monday, Tuesday, etc."
              />
              <CustomPicker
                options={[
                  { label: "Half Day", value: "half" },
                  { label: "Full Day", value: "full" },
                ]}
                title="Half day/full day"
                value={details.length}
                onChange={(value) => setDetails({ ...details, length: value })}
              />
            </View>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <Checkbox defaultChecked checked={agreeToPolicy} onChange={handleAgree} style={{color: 'white'}} size={"small"} />
              <PrimaryText
                fontSize={12}
                fontWeight={300}
                // style={{ color: COLORS.primary.error }}
              >
                {"I've read and agree to Max's booking policy."}
              </PrimaryText>
            </View>

            <TouchableOpacity
              style={[style.button, !submitEnabled && { opacity: 0.5 }]}
              disabled={!submitEnabled}
              onPress={submitForm}
            >
              <PrimaryText
                fontSize={12}
                fontWeight={800}
                style={{ color: COLORS.primary.white }}
              >
                Submit
              </PrimaryText>
            </TouchableOpacity>
          </ScrollView>
        </View>
      </View>
      {loading && (
        <ActivityIndicator
          size={"large"}
          animating={loading}
          style={[
            StyleSheet.absoluteFill,
            { backgroundColor: COLORS.primary.darkBlue },
          ]}
        />
      )}
    </>
  );
}

const styles = (size) =>
  StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      backgroundColor: COLORS.primary.darkAcc,
    },
    body: {
      paddingVertical: 12,
      flexGrow: 1,
      backgroundColor: COLORS.primary.darkBlue,
      width: "100%",
      maxWidth: 700,
      minHeight: size.height,
      // padding: 12,
    },
    inputContainer: {
      paddingHorizontal: 12,
    },
    scrollView: {
      flex: 1,
      minHeight: size.height,
    },
    input: {
      marginBottom: 12,
    },
    inputTitle: {
      marginTop: 12,
      marginBottom: 6,
      paddingLeft: 10,
      color: COLORS.primary.white,
    },
    button: {
      backgroundColor: COLORS.primary.lightBlue,
      borderRadius: 100,
      paddingHorizontal: 12,
      paddingVertical: 6,
      alignSelf: "center",
      margin: 12,
      marginBottom: 24,
    },
  });
