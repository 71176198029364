import { PrimaryText } from "components/Common/PrimaryText";
import React from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  View,
  TouchableOpacity,
} from "react-native-web";
// import { AlertCircle } from 'react-native-feather';
import { COLORS } from "constants/Colors";
import moment from "moment";
import { FaCalendar, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { RiAlertFill } from "react-icons/ri";

export const CustomInput = (props) => {
  const {
    title,
    placeholder,
    onChange,
    onChangeText,
    errorMessage,
    value,
    text,
    secure,
    date,
    time,
    onPress,
    multiline,
    keyboardType,
    timeModalOpen,
    onBlur,
    darkMode,
    maxLength,
    mandatory,
  } = props;

  const style = styles(multiline);

  return (
    <View style={[style.container, props?.style && { ...props.style }]}>
      {!!title && (
        <PrimaryText fontSize={14} fontWeight={700} style={style.inputTitle}>
          {title}
          {mandatory && (
            <PrimaryText
              fontSize={14}
              fontWeight={700}
              style={{ color: COLORS.primary.error }}
            >
              {"*"}
            </PrimaryText>
          )}
        </PrimaryText>
      )}

      {!date && (
        <TextInput
          {...props}
          maxFontSizeMultiplier={1}
          style={style.input}
          placeholder={placeholder}
          keyboardType={keyboardType}
          placeholderTextColor={"lightgrey"}
          value={value}
          onChange={onChange}
          onChangeText={onChangeText}
          secureTextEntry={secure}
          textContentType={"oneTimeCode"}
          multiline={multiline}
          maxLength={maxLength || 500}
          onBlur={onBlur}
          autoCapitalize={"none"}
        />
      )}
      {date && (
        <TouchableOpacity
          onPress={onPress}
          disabled={!onPress}
          style={style.input}
        >
          {time ? (
            <PrimaryText fontSize={16} fontWeight={400} style={style.value}>
              {moment(value).format("LT")}
            </PrimaryText>
          ) : (
            <PrimaryText fontSize={16} fontWeight={400} style={style.value}>
              {text || value.toLocaleDateString()}
            </PrimaryText>
          )}
          <View>
            {time ? (
              !timeModalOpen ? (
                <FaChevronDown style={style.icon} />
              ) : (
                <FaChevronUp style={style.icon} />
              )
            ) : (
              <FaCalendar style={style.icon} />
            )}
          </View>
        </TouchableOpacity>
      )}
      {errorMessage && (
        <View style={style.errorContainer}>
          <RiAlertFill
            width={16}
            height={16}
            fill={COLORS.primary.error}
            stroke={COLORS.primary.white}
          />
          <PrimaryText
            fontSize={14}
            fontWeight={400}
            style={style.errorMessage}
          >
            {errorMessage}
          </PrimaryText>
        </View>
      )}
    </View>
  );
};

const styles = (multiline) =>
  StyleSheet.create({
    container: {
      flexGrow: 1,
      flexDirection: "column",
      marginVertical: 6,
      // marginBottom: 12,
    },
    inputTitle: {
      paddingLeft: 10,
      marginBottom: 5,
      color: COLORS.primary.white,
    },
    input: {
      // height: multiline ? 128 : 30,
      paddingVertical: 4,
      paddingHorizontal: 16,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",

      fontSize: 14,
      color: COLORS.primary.white,
      fontWeight: 400,
      fontFamily: 'Montserrat',
      borderColor: "#999999",
      borderWidth: 1,
      borderRadius: 15,
      borderColor: COLORS.primary.lightGray
    },
    value: {
      color: COLORS.primary.white,
    },
    icon: {
      tintColor: COLORS.primary.darkGray,
      height: 24,
      width: 24,
    },
    errorContainer: {
      backgroundColor: COLORS.background.lightRed,
      justifyContent: "flex-start",
      alignItems: "center",
      paddingHorizontal: 16,
      paddingVertical: 8,
      borderRadius: 8,
      flexDirection: "row",
    },
    errorMessage: {
      color: COLORS.primary.error,
      paddingLeft: 8,
    },
  });
