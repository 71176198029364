export const COLORS = {
  primary: {
    lightBlue: '#1DA1F2',
    darkBlue: '#001935',
    darkAcc: 'rgba(27,47,69,1)',
    white: '#FFF',
    black: '#000',
    error: '#EE1D52',
    gray: '#999999',
    lightGray: '#C4C4C4',
    darkGray: '#444444',
    sunflower: '#FFE000',
    orange: '#FEAB09',
    lightOrange: '#F9CF93',
    green: '#98D15E'
  },
  secondary: {
    offWhite: '#F6F6F6',
    opaqueWhite: 'rgba(255,255,255,0.95)',
    gray: '#828282',
    darkBlue: '#0D243E'
  },
  background: {
    darkBlue: '#001935',
    lightBlue: '#1DA1F2',
    darkOrange: '#FEAB09',
    white: '#ffffff',
    offWhite: 'rgba(255,255,255,0.95)',
    lightRed: '#FEEFEF',
  },
  text: {
    white: '#ffffff',
    lightBlue: '#1DA1F2',
    gray: '#828282',
    lightGray: '#C4C4C4',
    darkGrey: '#444444',
    error: '#EE1D52'
  }
}

export const darkModeBg = (darkMode) => {
  return darkMode ? COLORS.primary.darkBlue : COLORS.primary.white
}

export const darkModeSecondaryBg = (darkMode) => {
  return darkMode ? COLORS.secondary.darkBlue : COLORS.secondary.offWhite
}

export const darkModeBgOffwhite = (darkMode) => {
  return darkMode ? COLORS.primary.darkBlue : COLORS.secondary.offWhite
}

export const darkModeSecondaryBgWhite = (darkMode) => {
  return darkMode ? COLORS.secondary.darkBlue : COLORS.primary.white
}



export const getDarkModeText = (darkMode) => {
  return darkMode ? COLORS.primary.white : COLORS.primary.black
}

export const getDarkModeGrayText = (darkMode) => {
  return darkMode ? COLORS.primary.white : COLORS.primary.darkGray
}

export const darkModeGray = (darkMode) => {
  return darkMode ? COLORS.primary.lightGray : COLORS.primary.gray
}
