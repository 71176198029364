import { COLORS } from "constants/Colors";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import { useWindowSize } from "utils/helpers";
import { useInstagram } from "context/instagramContext";
import { firestore, storage } from "../utils/firebase";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { AddCircle, MenuOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export function Header(props) {
  const { home } = props;
  const size = useWindowSize();
  const navigate = useNavigate();
  const { instagramPhotos } = useInstagram();
  const [loading, setLoading] = useState(false);

  const [details, setDetails] = useState({});
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const [bodyPhotos, setBodyPhotos] = useState([]);
  const [referencePhotos, setReferencePhotos] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (instagramPhotos.length > 0) setLoading(false);
  }, [instagramPhotos]);

  //check if submit button is enabled
  useEffect(() => {
    // console.log("details", details);
    setSubmitEnabled(
      details.over18 &&
        details.name &&
        details.email &&
        details.phone &&
        details.location &&
        details.description &&
        details.month
    );
  }, [details]);

  function photoSelected(id) {
    if (selectedPhotos.includes(id)) {
      setSelectedPhotos(selectedPhotos.filter((photo) => photo !== id));
    } else {
      setSelectedPhotos([...selectedPhotos, id]);
    }
  }

  function submitForm() {
    setLoading(true);

    updateInquiryPics();
  }

  const updateInquiryPics = () => {
    const inquiryRef = firestore
      .collection("users")
      .doc("maxbtattin")
      .collection("inquiries")
      .doc();
    let promises = [];
    const bodyPhotosURLS = [];
    const referencePhotoURLS = [];
    bodyPhotos.map((val, index) => {
      let ref = storage.ref(`${inquiryRef.id}/bodyPhotos/${index}.jpg`);

      promises.push(
        ref
          .put(val, { cacheControl: "max-age=31536000" })
          .then(async (result) => {
            // console.log("result", result);
            const downloadURL = await ref.getDownloadURL();
            bodyPhotosURLS.push(downloadURL);
          })
          .catch((err) => {
            console.log("err", err);
          })
      );
    });
    referencePhotos.map((val, index) => {
      let ref = storage.ref(`${inquiryRef.id}/referencePhotos/${index}.jpg`);

      promises.push(
        ref
          .put(val, { cacheControl: "max-age=31536000" })
          .then(async (result) => {
            // console.log("profile pic upload result", result);
            const downloadURL = await ref.getDownloadURL();
            referencePhotoURLS.push(downloadURL);
          })
          .catch((err) => {
            console.log("err", err);
          })
      );
    });

    Promise.all(promises).then(() => {
      inquiryRef
        .set({
          ...details,
          selectedPhotos,
          referencePhotos: referencePhotoURLS,
          bodyPhotos: bodyPhotosURLS,
        })
        .then(() => {
          setLoading(false);
          // console.log("success");
          alert("Your inquiry has been submitted!");
          setDetails({});
          setSelectedPhotos([]);
          setBodyPhotos([]);
          setReferencePhotos([]);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
          alert(
            "There was an error submitting your inquiry. Please try again later."
          );
        });
    });
  };

  //   Over 18?		checkbox
  // Name		text
  // Pronouns	y	text
  // email		email
  // phone #		phone
  // location on body		text
  // estimated size		text
  // description of tattoo		text
  // select a few instagram posts you like		multi-select
  // upload photos for reference	y	upload button with image display
  // desired month, what days work best (leave blank if open to next available)	y	month picker, day picker... or just text
  // half day/full day		dropdown

  //i've read this policy and understand

  const style = styles(size);

  // console.log("bodyPhotos", bodyPhotos);

  return (
    <View
      style={{
        alignSelf: "flex-start",
        flex: home ? undefined : 1,
        alignItems: "flex-end",
      }}
    >
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuOutlined fontSize={"large"} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => navigate("/")}>Gallery</MenuItem>
        <MenuItem onClick={() => navigate("/contact")}>Inquiry Form</MenuItem>
        <MenuItem onClick={() => navigate("/pricing")}>Pricing</MenuItem>
      </Menu>
    </View>
  );
}

const styles = (size) =>
  StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      backgroundColor: COLORS.primary.darkAcc,
    },
    body: {
      paddingVertical: 12,
      flexGrow: 1,
      backgroundColor: COLORS.primary.darkBlue,
      width: "100%",
      maxWidth: 700,
      minHeight: size.height,
      // padding: 12,
    },
    inputContainer: {
      paddingHorizontal: 12,
    },
    scrollView: {
      flex: 1,
      minHeight: size.height,
    },
    input: {
      marginBottom: 12,
    },
    inputTitle: {
      marginTop: 12,
      marginBottom: 6,
      paddingLeft: 10,
      color: COLORS.primary.white,
    },
    button: {
      backgroundColor: COLORS.primary.lightBlue,
      borderRadius: 100,
      paddingHorizontal: 12,
      paddingVertical: 6,
      alignSelf: "center",
      margin: 12,
      marginBottom: 24,
    },
  });
