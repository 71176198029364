import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  ActivityIndicator,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native-web";
import { useWindowSize } from "utils/helpers";
import { useInstagram } from "context/instagramContext";
import { firestore, storage } from "../utils/firebase";

import max from "assets/max-headshot.png";
import { useNavigate } from "react-router-dom";
import { Header } from "../components/Header";

export default function Pricing() {
  const size = useWindowSize();
  const navigate = useNavigate();
  const { instagramPhotos } = useInstagram();
  const [loading, setLoading] = useState(false);

  const [details, setDetails] = useState({});
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const [bodyPhotos, setBodyPhotos] = useState([]);
  const [referencePhotos, setReferencePhotos] = useState([]);

  useEffect(() => {
    if (instagramPhotos.length > 0) setLoading(false);
  }, [instagramPhotos]);

  //check if submit button is enabled
  useEffect(() => {
    // console.log("details", details);
    setSubmitEnabled(
      details.over18 &&
        details.name &&
        details.email &&
        details.phone &&
        details.location &&
        details.description &&
        details.month
    );
  }, [details]);

  function photoSelected(id) {
    if (selectedPhotos.includes(id)) {
      setSelectedPhotos(selectedPhotos.filter((photo) => photo !== id));
    } else {
      setSelectedPhotos([...selectedPhotos, id]);
    }
  }

  function submitForm() {
    setLoading(true);

    updateInquiryPics();
  }

  const updateInquiryPics = () => {
    const inquiryRef = firestore
      .collection("users")
      .doc("maxbtattin")
      .collection("inquiries")
      .doc();
    let promises = [];
    const bodyPhotosURLS = [];
    const referencePhotoURLS = [];
    bodyPhotos.map((val, index) => {
      let ref = storage.ref(`${inquiryRef.id}/bodyPhotos/${index}.jpg`);

      promises.push(
        ref
          .put(val, { cacheControl: "max-age=31536000" })
          .then(async (result) => {
            // console.log("result", result);
            const downloadURL = await ref.getDownloadURL();
            bodyPhotosURLS.push(downloadURL);
          })
          .catch((err) => {
            console.log("err", err);
          })
      );
    });
    referencePhotos.map((val, index) => {
      let ref = storage.ref(`${inquiryRef.id}/referencePhotos/${index}.jpg`);

      promises.push(
        ref
          .put(val, { cacheControl: "max-age=31536000" })
          .then(async (result) => {
            // console.log("profile pic upload result", result);
            const downloadURL = await ref.getDownloadURL();
            referencePhotoURLS.push(downloadURL);
          })
          .catch((err) => {
            console.log("err", err);
          })
      );
    });

    Promise.all(promises).then(() => {
      inquiryRef
        .set({
          ...details,
          selectedPhotos,
          referencePhotos: referencePhotoURLS,
          bodyPhotos: bodyPhotosURLS,
        })
        .then(() => {
          setLoading(false);
          // console.log("success");
          alert("Your inquiry has been submitted!");
          setDetails({});
          setSelectedPhotos([]);
          setBodyPhotos([]);
          setReferencePhotos([]);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
          alert(
            "There was an error submitting your inquiry. Please try again later."
          );
        });
    });
  };

  //   Over 18?		checkbox
  // Name		text
  // Pronouns	y	text
  // email		email
  // phone #		phone
  // location on body		text
  // estimated size		text
  // description of tattoo		text
  // select a few instagram posts you like		multi-select
  // upload photos for reference	y	upload button with image display
  // desired month, what days work best (leave blank if open to next available)	y	month picker, day picker... or just text
  // half day/full day		dropdown

  //i've read this policy and understand

  const style = styles(size);

  // console.log("bodyPhotos", bodyPhotos);

  return (
    <>
      <View style={style.container}>
        <View style={style.body}>
          <ScrollView
            style={style.scrollView}
            contentContainerStyle={{ paddingBottom: 30 }}
            showsVerticalScrollIndicator={false}
          >
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View style={{ flex: 1 }} />
              <View>
                <Image
                  source={max}
                  style={{
                    borderRadius: 400,
                    width: size.width / 4,
                    height: size.width / 4,
                    marginBottom: 24,
                    alignSelf: "center",
                  }}
                  alt={"Max Bontrager"}
                  resizeMode="cover"
                />
                <PrimaryText
                  fontSize={20}
                  fontWeight={800}
                  style={{
                    marginBottom: 12,
                    color: COLORS.primary.white,
                    textAlign: "center",
                  }}
                >
                  PRICING
                </PrimaryText>
              </View>

              <Header />
            </View>
            <View style={{ paddingHorizontal: 12 }}>
              <PrimaryText fontSize={12} fontWeight={400}>{`
I’m currently charging $200/hr 

For larger scale projects I ask for a $200 deposit 
$100 will go towards my time for designing your tattoo and $100 will come off the total at the end of your last session per project. 

Half day or less I’ll ask for a $100 deposit and this will be deducted off your session! 

Deposits are non refundable. 


I prefer to operate in half (4 hr) or full day (6hr) increments, most of my tattoos take around this time frame to do however,
I can always do my best to accommodate the budget you have for each session :) 

I do have a minimum of $300 for smaller tattoos ( palm size and under ) 
              `}</PrimaryText>
            </View>
            <TouchableOpacity
              style={style.button}
              onPress={() => navigate("/contact")}
            >
              <PrimaryText fontSize={12} fontWeight={800}>
                Book Now
              </PrimaryText>
            </TouchableOpacity>
          </ScrollView>
        </View>
      </View>
      {loading && (
        <ActivityIndicator
          size={"large"}
          animating={loading}
          style={[
            StyleSheet.absoluteFill,
            { backgroundColor: COLORS.primary.darkBlue },
          ]}
        />
      )}
    </>
  );
}

const styles = (size) =>
  StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      backgroundColor: COLORS.primary.darkAcc,
    },
    body: {
      paddingVertical: 12,
      flexGrow: 1,
      backgroundColor: COLORS.primary.darkBlue,
      width: "100%",
      maxWidth: 700,
      minHeight: size.height,
      // padding: 12,
    },
    inputContainer: {
      paddingHorizontal: 12,
    },
    scrollView: {
      flex: 1,
      minHeight: size.height,
    },
    input: {
      marginBottom: 12,
    },
    inputTitle: {
      marginTop: 12,
      marginBottom: 6,
      paddingLeft: 10,
      color: COLORS.primary.white,
    },
    button: {
      backgroundColor: COLORS.primary.lightBlue,
      borderRadius: 100,
      paddingHorizontal: 12,
      paddingVertical: 6,
      alignSelf: "center",
      margin: 12,
      marginBottom: 24,
    },
  });
