import React from 'react';
import {Text, StyleSheet, Dimensions} from 'react-native-web';
import { COLORS } from 'constants/Colors';
import { useWindowSize } from '../../utils/helpers';

const WIDTH_OF_DESIGN_SCREEN = 414

export const PrimaryText = (props) => {
    const size = useWindowSize()
    const {width} = size;
    const {fontSize = 12, fontWeight = 400, style:customStyles, numberOfLines} = props

    let style = styles(fontSize, fontWeight, width)
    return (
        <Text 
        {...props}
        style={[style.title, customStyles]} 
        numberOfLines={numberOfLines ? numberOfLines : 0}
        
        >{props.children}</Text>
    );
};

const styles = (fontSize, fontWeight, screenWidth) => StyleSheet.create({
    title: {
        fontSize: fontSize/WIDTH_OF_DESIGN_SCREEN*screenWidth,
        lineHeight: fontSize/WIDTH_OF_DESIGN_SCREEN*screenWidth*1.25,
        color: COLORS.primary.white,
        fontFamily: 'Montserrat',
        fontWeight: fontWeight,
        textAlign: 'left'
    }
});
