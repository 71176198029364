import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import "firebase/compat/functions"

const firebaseConfig = {
  apiKey: "AIzaSyDq6KWyaXYmi-zfuX7_TnIR85oEnJ1Aju8",
  authDomain: "maxbtattin-303.firebaseapp.com",
  projectId: "maxbtattin-303",
  storageBucket: "maxbtattin-303.appspot.com",
  messagingSenderId: "798200502300",
  appId: "1:798200502300:web:5cd5f95a78ba8d46c727d9"
}
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)

export const auth = app.auth()
export const firestore = app.firestore()
export const storage = app.storage()
export const functions = app.functions()

export default app
// export default firebase;
