import React, { createContext, useEffect, useContext, useState, FC } from "react";
import { firestore } from "../utils/firebase";

interface InstagramContextInterface {
  instagramPhotos: any[];
  setInstagramPhotos: (photos: any[]) => void;
}
type InstagramData = {
  id: string
  media_url: string
  media_type: string
}

type JSONResponse = {
  data?: InstagramData[]
  errors?: Array<{message: string}>
}

export const InstagramContext = createContext<InstagramContextInterface>({
  instagramPhotos: [],
  setInstagramPhotos: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const InstagramProvider: FC<Props> = ({ children }) => {

  const [instagramPhotos, setInstagramPhotos] = useState([]);

  useEffect(() => {
    getUserData();
  }, []);

  async function getUserData() {
    const query = await firestore.collection("users").doc("maxbtattin").get();
    const data = query.data();
    getMax(data?.accessToken);
  }

  async function getMax(accessToken: string) {
    try {
      const photos = await fetchInstagramPhotos(
        `https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type&access_token=${accessToken}&limit=100`
      );
      setInstagramPhotos(photos.filter((x: any) => x.media_type !== "VIDEO"));
      requestRefreshToken(accessToken);
      // Do something with the photos
    } catch (e) {
      console.error("Fetching Instagram photos failed", e);
    }
  }

  async function requestRefreshToken(accessToken: string) {
    try {
      const response = await fetch(
        `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${accessToken}`
      );
      if (!response.ok) throw new Error(response.statusText);
      const { access_token } = await response.json();
      firestore.collection("users").doc("maxbtattin")
      .update({
        accessToken: access_token,
        accessTokenLastUpdated: new Date()
      })
    } catch (e) {
      console.error("Fetching Instagram photos failed", e);
    }
  }



  const fetchInstagramPhotos = async (accountUrl: RequestInfo | URL) => {
    const response = await fetch(accountUrl)
    if (!response.ok) throw new Error(response.statusText)
    const {data, errors}: JSONResponse = await response?.json()
    return data;
  };

  return (
    <InstagramContext.Provider
      value={{
        instagramPhotos,
        setInstagramPhotos,
      }}
    >
      {children}
    </InstagramContext.Provider>
  );
};

export const useInstagram = () => useContext(InstagramContext);
