import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
} from "react-native-web";
import { useWindowSize } from "utils/helpers";
// import Image from "next/image";
import { useInstagram } from "context/instagramContext";
import { useNavigate } from "react-router-dom";

import max from "assets/max-headshot.png";
import { Header } from "../components/Header";

export default function Home() {
  const size = useWindowSize();
  const { instagramPhotos } = useInstagram();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (instagramPhotos.length > 0) setLoading(false);
  }, [instagramPhotos]);

  const linkPressed = (id) => {
    window.open(`https://www.instagram.com/${id}/`, "_blank");
  };

  const style = styles(size);

  return (
    <>
      <View style={style.container}>
        <View style={style.body}>
          <ScrollView
            contentContainerStyle={style.scrollView}
            showsVerticalScrollIndicator={false}
          >
            <View style={style.header}>
              <Image
                source={max}
                style={{
                  borderRadius: 400,
                  width: size.width / 4,
                  height: size.width / 4,
                  margin: 12
                }}
                alt={"Max Bontrager"}
                resizeMode="cover"
              />
              <View style={style.headerRightContainer}>
                <PrimaryText fontSize={20} fontWeight={900}>
                  @maxbtattin
                </PrimaryText>
                <PrimaryText fontSize={14} fontWeight={500}>
                  Max Bontrager
                </PrimaryText>

                <PrimaryText fontSize={14} fontWeight={400}>
                  {`Denver, CO`}
                </PrimaryText>
              </View>
              <Header home/>
            </View>
            <View style={{ paddingHorizontal: 12 }}>
              <PrimaryText fontSize={12} fontWeight={400}>
                Tattooing at{" "}
                <PrimaryText
                  style={{ color: COLORS.primary.lightBlue }}
                  onPress={() => linkPressed("longlivetattoocollective")}
                >
                  @longlivetattoocollective
                </PrimaryText>{" "}
                DEN
              </PrimaryText>
              <PrimaryText fontSize={12} fontWeight={400}>
                Resident guest at{" "}
                <PrimaryText
                  style={{ color: COLORS.primary.lightBlue }}
                  onPress={() => linkPressed("theartistryrealm")}
                >
                  @theartistryrealm
                </PrimaryText>{" "}
                INDY
              </PrimaryText>
              <PrimaryText fontSize={12} fontWeight={400}>
                {`Books OPEN`}
              </PrimaryText>
              <TouchableOpacity
                style={style.button}
                onPress={() => navigate("/contact")}
              >
                <PrimaryText fontSize={12} fontWeight={800}>
                  Inquiry Form
                </PrimaryText>
              </TouchableOpacity>
            </View>

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {instagramPhotos.map((photo) => {
                const { media_url, id } = photo;
                return (
                  <Image
                    key={id}
                    source={{ uri: media_url }}
                    layout="fixed"
                    style={{
                      width: size.width / 3 - 4,
                      height: undefined,
                      margin: 1,
                      aspectRatio: 1,
                    }}
                    alt={id}
                    // resizeMode="contain"
                  />
                );
              })}
            </View>
          </ScrollView>
        </View>
      </View>
      {loading && (
        <ActivityIndicator
          size={"large"}
          animating={loading}
          style={[
            StyleSheet.absoluteFill,
            { backgroundColor: COLORS.primary.darkBlue },
          ]}
        />
      )}
    </>
  );
}

const styles = (size) =>
  StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      backgroundColor: COLORS.primary.darkAcc,
    },
    body: {
      flexGrow: 1,
      backgroundColor: COLORS.primary.darkBlue,
      width: "100%",
      maxWidth: 700,
      minHeight: size.height,
      // padding: 12,
    },
    scrollView: {
      flex: 1,
      minHeight: size.height,
      // alignItems: "center",
    },
    header: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 12,
      paddingVertical: 12,
    },
    headerRightContainer: {
      flex: 1,
      // padding: 12,
    },
    button: {
      backgroundColor: COLORS.primary.lightBlue,
      borderRadius: 100,
      paddingHorizontal: 12,
      paddingVertical: 6,
      alignSelf: "center",
      margin: 12,
      marginBottom: 24,
    },
  });
