import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Home from "pages/home";
import Contact from "pages/contact";
import Pricing from "pages/pricing";
import { InstagramProvider } from "context/instagramContext";
import { Policy } from "@mui/icons-material";

export default function MainNavigator() {
  return (
    <InstagramProvider>
      <Router>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/contact"} element={<Contact />} />
          <Route path={"/pricing"} element={<Pricing />} />
          <Route path={"/policy"} element={<Policy />} />
          <Route path={"/*"} element={<Navigate replace to={"/"} />} />
        </Routes>
      </Router>
    </InstagramProvider>
  );
}
