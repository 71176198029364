import React, { FC } from "react";
import { InstagramProvider } from "./context/instagramContext";
import MainNavigator from "./navigators/MainNavigator";
import 'App.css'

type Props = {
  Component: React.FC;
  pageProps: any;
};

export const App: FC<Props> = ({ Component, pageProps }) => {
  return (
    <InstagramProvider>
      <MainNavigator {...pageProps} />
    </InstagramProvider>
  );
}
